<template>
  <Footer class="DialogFooter">
    <slot></slot>
  </Footer>
</template>

<script setup lang="ts"></script>

<style scoped lang="scss">
.DialogFooter {
  padding-inline: 32px;
  border-top: 1px solid var(--gray-6);
  justify-content: flex-end;
}
</style>
